import React, { useContext } from "react";
import { Grommet, Box, Grid, ResponsiveContext } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GlobalHeader } from "../components";
import {
	Dashboard,
	LoginPage,
	LandingPage,
	HelpPage,
	Demo,
	StorageCalendar,
	PageNotFound,
	ProductGroup,
} from "../pages";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import CompetitorDashboard from "../pages/CompetitorDashboard";
import CompetitorProductListPage from "../pages/CompetitorProductListPage";
import CompetitorBattleCards from "../pages/CompetitorBattleCards";
import CompetitorReportPage from "../pages/CompetitorReportPage";
import backgroud_img from "../static/svg/bg_img.svg"
import NotificationCenter from "../pages/NotificationCenter";

export const PrivateLayout = ({ authenticated, checked }) => {
	const size = useContext(ResponsiveContext);

	return (
		<Grommet theme={hpe} full>
			{checked && (
				<>
					<Grid
						rows={[
							["xsmall", "126px"],
							[size === "small" ? "auto" : "large", "auto"],
							["xxsmall", size === "small" ? "small" : size === 'xlarge' ? "xsmall" : "xxsmall"],
						]}
						columns={["auto"]}
						areas={[["header"], ["main"], ["footer"]]}
					>
						<Box gridArea='header' justify='start'>
							<div id='header' className='header' />
							<GlobalHeader />
						</Box>
						<Box
							gridArea='main'
							background={{ image: `url(${backgroud_img})` }}
							justify='start'
							fill='horizontal'
							height='auto'
							margin={{ horizontal: "auto" }}
							width={{ max: "1800px" }}
						>
							<Switch>
								<Route path='/index' component={LandingPage} />
								<Route path='/login' component={LoginPage} />
								<PrivateRoute exact path='/' component={CompetitorDashboard} />
								<PrivateRoute exact path='/dashboard' component={Dashboard} />
								<PrivateRoute exact path='/calendar' component={StorageCalendar} />
								<PrivateRoute exact path='/product-group/:id' component={ProductGroup} />
								<PrivateRoute exact path='/product-group/:product_group_id/demo/:id' component={Demo} />
								<PrivateRoute exact path='/:product_group_id/demo/:id' component={Demo} />
								<PrivateRoute exact path='/learn-more' component={HelpPage} />
								<PrivateRoute exact path='/competitor' component={CompetitorDashboard} />
								<PrivateRoute exact path='/competitor/:id' component={CompetitorProductListPage} />
								<PrivateRoute exact path='/tools/:id&:name' component={CompetitorProductListPage} />
								{/* <PrivateRoute exact path='/competitor/:id/:product_id/resource-center' component={CompetitorResourceCenter} /> */}
								<PrivateRoute exact path='/competitor/:id/:product_id/resource-center/:res_id' component={CompetitorBattleCards} />
								<PrivateRoute exact path="/competitor/notification-center" component={NotificationCenter} />
								<PrivateRoute exact path='/competitor/admin-report' component={CompetitorReportPage} />
								<Route component={PageNotFound} />
							</Switch>
						</Box>
						<Box gridArea='footer' justify="end">
							<div id='footer' className='footer' />
						</Box>
					</Grid>
				</>
			)}
		</Grommet>
	);
};

const { bool } = PropTypes;

PrivateLayout.propTypes = {
	authenticated: bool.isRequired,
	checked: bool.isRequired,
};

const mapState = ({ session }) => ({
	checked: session.checked,
	authenticated: session.authenticated,
});

export default connect(mapState)(PrivateLayout);
